import React, { useState, useEffect, createContext } from 'react';

//Creamos el contexto y lo almacenamos en una variable.
export const SsvContext = createContext();

//const BASEURL = 'http://localhost:8001/';
const BASEURL = 'https://ssvback.vercel.app/';

//Creamos el componente funcional que va a proveer a unos "children" de cierta información
export const SsvContextProvider = ({ children }) => {
  const [meetings, setMeetings] = useState([]);
  const [brotherhoods, setBrotherhoods] = useState([]);
  const [heroimages, setHeroimages] = useState([]);
  const [imagineros, setImagineros] = useState([]);
  const [pasos, setPasos] = useState([]);
  const [processions, setProcessions] = useState([]);
  const [nextprocessions, setNextProcessions] = useState([]);
  const [news, setNews] = useState([]);
  const [lastnews, setLastNews] = useState([]);
  const [blog, setBlog] = useState([]);

  //Ejecutamos todas las llamadas para que al ejecutar el SsvContextProvider ya tengamos la información de una API
  useEffect(() => {
    const getMeeting = async () => {
      const MeetingApi = await fetch(`${BASEURL}calendario`);
      const MeetingJSON = await MeetingApi.json();
      setMeetings(MeetingJSON.meetings);
    };
    getMeeting();
  }, []);
  useEffect(() => {
    const getMeeting = async () => {
      const MeetingApi = await fetch(`${BASEURL}calendario25`);
      const MeetingJSON = await MeetingApi.json();
      setMeetings(MeetingJSON.meetings);
    };
    getMeeting();
  }, []);
  useEffect(() => {
    const getBrotherhoods = async () => {
      const BrotherhoodsApi = await fetch(`${BASEURL}cofradias`);
      const BrotherhoodsJSON = await BrotherhoodsApi.json();
      setBrotherhoods(BrotherhoodsJSON.brotherhood);
    };
    getBrotherhoods();
  }, []);

  useEffect(() => {
    const getHeroimages = async () => {
      const HeroimagesApi = await fetch(`${BASEURL}heroimages`);
      const HeroimagesJSON = await HeroimagesApi.json();
      setHeroimages(HeroimagesJSON.heroimages);
    };
    getHeroimages();
  }, []);

  useEffect(() => {
    const getImagineros = async () => {
      const ImaginerosApi = await fetch(`${BASEURL}imagineros`);
      const ImaginerosJSON = await ImaginerosApi.json();
      setImagineros(ImaginerosJSON.imagineros);
    };
    getImagineros();
  }, []);

  useEffect(() => {
    const getPasos = async () => {
      const PasosApi = await fetch(`${BASEURL}pasos`);
      const PasosJSON = await PasosApi.json();
      setPasos(PasosJSON.paso);
    };
    getPasos();
  }, []);

  useEffect(() => {
    const getProcessions = async () => {
      const ProcessionsApi = await fetch(`${BASEURL}procesiones`);
      const ProcessionsJSON = await ProcessionsApi.json();
      setProcessions(ProcessionsJSON.procession);
      setNextProcessions(ProcessionsJSON.procession.slice(0, 3));
    };
    getProcessions();
  }, []);

  useEffect(() => {
    const getNews = async () => {
      const NewsApi = await fetch(`${BASEURL}noticias`);
      const NewsJSON = await NewsApi.json();
      setNews(NewsJSON.news);
      setLastNews(NewsJSON.news.slice(0,3))
    };
    getNews();
  }, []);

  useEffect(() => {
    const getBlog = async () => {
      const BlogApi = await fetch(`${BASEURL}blog`);
      const BlogJSON = await BlogApi.json();
      setBlog(BlogJSON.blog);
    };
    getBlog();
  }, []);

  //En el retorno le indicamos que provea del SWContext a los children de x valores
  return (
    <SsvContext.Provider
      value={{
        meetings,
        brotherhoods,
        heroimages,
        imagineros,
        pasos,
        processions,
        nextprocessions,
        news,
        lastnews,
        blog,
      }}>
      {children}
    </SsvContext.Provider>
  );
};
