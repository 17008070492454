export const getParsedDateCalendarPage = (dateString) => {
  const date = new Date(dateString);
  const monthNames = [
    'Enero', 
    'Febrero', 
    'Marzo', 
    'Abril', 
    'Mayo', 
    'Junio',
    'Julio', 
    'Agosto', 
    'Septiembre', 
    'Octubre', 
    'Noviembre', 
    'Diciembre'
  ];
  const yearCalendar = date.getFullYear();
  const mesCalendar = monthNames[date.getMonth()];
  const dayCalendar = date.getDate();
  return [mesCalendar, dayCalendar, yearCalendar];
};
