import React, { useState, useContext, useEffect } from 'react';
import { SsvContext } from '../../context/context';
import { Button } from '../../Components/Button/Button';
import SectionHeader from '../../Components/Static/SectionHeader';
import ReactGA from 'react-ga4';
import Loader from '../../Components/Loader/Loader';
import { getCurrentYearDate, FormattedDate } from './DateUtils';

const imagesurl = '../../../images/meetings/';

const Calendar = () => {
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: `/calendario` });
  }, []);

  const { meetings } = useContext(SsvContext);
  let [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {meetings ? (
        <>
          {loaded ? (
            <>
              <SectionHeader name={`Semana Santa ${new Date().getFullYear()}`} />
              <ul className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-5'>
              {meetings
                .sort((a, b) => new Date(a.date) - new Date(b.date))
                .map((meet) => {
                console.log('Processing Meeting:', meet.dayname);
                console.log('Meet Dates:', JSON.stringify(meet.dates));
                
                const currentDate = getCurrentYearDate(meet.dates);

                
                console.log('Current Date for ' + meet.dayname + ':', currentDate);    
                return (
                  <li className='w-full drop-shadow-card-ssv' key={meet._id.$oid}>
                    <div
                      className='h-32 md:h-64 p-4 bg-cover'
                      style={{
                        backgroundImage: `url(${imagesurl}${meet.slug}/${meet.picture[0].picurl})`,
                      }}>
                      <div className='bg-ssv_yellow w-fit h-auto text-black drop-shadow-card-ssv p-3 flex flex-col text-center'>
                        {currentDate && !isNaN(currentDate.getTime()) ? (
                          <FormattedDate date={currentDate} />
                        ) : (
                          <span>Fecha no disponible para {meet.dayname}</span>
                        )}
                      </div>
                    </div>
                    <div className='bg-black p-2 md:p-4 flex justify-end'>
                      <Button
                        title={meet.dayname}
                        destino={meet.slug}
                        type='secondary'
                        className=''></Button>
                    </div>
                  </li>
                );
              })}
              </ul>
            </>
          ) : (
            <Loader loaded={loaded} />
          )}
        </>
      ) : null}
    </>
  );
};

export default Calendar;
