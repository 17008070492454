import React from 'react';

export const getCurrentYearDate = (dates) => {
  const currentYear = new Date().getFullYear();
  const index = currentYear - 2025;
  
  console.log('DEBUG getCurrentYearDate:');
  console.log('Current Year:', currentYear);
  console.log('Calculated Index:', index);
  console.log('Dates Array:', JSON.stringify(dates));
  
  if (dates && Array.isArray(dates) && dates.length > 0) {
    dates.forEach((dateString, idx) => {
      console.log(`Date ${idx}:`, dateString);
      const parsedDate = new Date(dateString);
      console.log(`Date ${idx} parsed:`, parsedDate);
    });

    if (index >= 0 && index < dates.length) {
      const selectedDate = new Date(dates[index]);
      console.log('Selected Date:', selectedDate);
      if (!isNaN(selectedDate.getTime())) {
        return selectedDate;
      }
    }
  }
  
  console.log('No valid date found');
  return null;
};

export const FormattedDate = ({ date }) => {
  console.log('FormattedDate received:', date);

  if (!date) {
    console.log('No date provided');
    return <span>Fecha no disponible</span>;
  }

  if (isNaN(date.getTime())) {
    console.log('Invalid date');
    return <span>Fecha inválida</span>;
  }

  // Ajustar la fecha para la zona horaria local
  const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

  return (
    <>
      <time className='font-ebgaramond text-xs'>
        {localDate.toLocaleString('es-ES', { weekday: 'long' })}
      </time>
      <time className='font-ebgaramond text-4xl'>
        {localDate.getDate()}
      </time>
      <time className='font-ebgaramond text-xs'>
        {localDate.toLocaleString('es-ES', { month: 'long' })}
      </time>
    </>
  );
};

