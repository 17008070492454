import React, { useEffect, useContext } from 'react';
import ReactGA from 'react-ga4';
import { SsvContext } from '../../context/context';
import Hero from '../../Components/Hero/Hero';
import NewsHome from '../../Components/NewsHome/NewsHome';
import ProcessionsHome from '../../Components/ProcessionsHome/ProcessionsHome';

const Home = () => {
  const { lastnews, nextprocessions, heroimages } = useContext(SsvContext);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: '/' });
  }, []);

  return (
    <div className="container mx-auto px-4">
      <h1 className='font-ebgaramond text-3xl my-8 p-2'>
        Semana Santa Valladolid
      </h1>
      <Hero heroimages={heroimages} />
      <section>
        <h2 className='font-ebgaramond text-3xl my-8'>
          Próximas procesiones
        </h2>
        <ProcessionsHome processions={nextprocessions} />
      </section>
      <section>
        <h2 className='font-ebgaramond text-3xl my-8'>Últimas noticias</h2>
        <NewsHome news={lastnews} />
      </section>
    </div>
  );
};

export default Home;
