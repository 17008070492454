import './App.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavBar from './Components/Static/NavBar/NavBar';
import Home from './pages/Home/Home';
import Calendar from './pages/Calendar/Calendar';
import CalendarDetail from './pages/Calendar/CalendarDetail';
import Brotherhoods from './pages/Brotherhoods/Brotherhoods';
import Processions from './pages/Processions/Processions';
import ProcessionDetail from './pages/Processions/ProcessionDetail';
import Pasos from './pages/Pasos/Pasos';
import PasosDetail from './pages/Pasos/PasosDetail';
import Imagineros from './pages/Imagineros/Imagineros';
import ImaginerosDetail from './pages/Imagineros/ImaginerosDetail';
import News from './pages/News/News';
import Blog from './pages/Blog/Blog';
import BlogDetail from './pages/Blog/BlogDetail';
import NewsDetail from './pages/News/NewsDetail';
import Contact from './pages/Contact/Contact';
import Footer from './Components/Static/Footer/Footer';
import { SsvContextProvider } from './context/context';
import BrotherhoodsDetail from './pages/Brotherhoods/BrotherhoodsDetail';

function App() {
  return (
    <SsvContextProvider>
      <Router>
        <NavBar />
        <section className='container mx-auto min-h-screen px-4 m-4'>
          <Routes>
            <Route path='/' element={<Home />}></Route>
            <Route path='/calendario' element={<Calendar />}></Route>
            <Route
              path='/calendario/:name'
              element={<CalendarDetail />}></Route>
            <Route path='/procesiones' element={<Processions />}></Route>
            <Route
              path='/procesiones/:name'
              element={<ProcessionDetail />}></Route>
            <Route path='/cofradias' element={<Brotherhoods />}></Route>
            <Route
              path='/cofradias/:name'
              element={<BrotherhoodsDetail />}></Route>
            <Route path='/pasos' element={<Pasos />}></Route>
            <Route path='/pasos/:name' element={<PasosDetail />}></Route>
            <Route path='/imagineros' element={<Imagineros />}></Route>
            <Route
              path='/imagineros/:name'
              element={<ImaginerosDetail />}></Route>
            <Route path='/noticias' element={<News />}></Route>
            <Route path='/noticias/:slug' element={<NewsDetail />}></Route>
            <Route path='/blog' element={<Blog />}></Route>
            <Route path='/blog/:slug' element={<BlogDetail />}></Route>
            <Route path='/contacto' element={<Contact />}></Route>
          </Routes>
        </section>
        <Footer />
      </Router>
    </SsvContextProvider>
  );
}

export default App;
