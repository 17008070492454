import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Logo from '../../Icons/Logo';
import NavLinksC from '../NavLinksC';
import './NavBar.scss';
import MegaMenu from '../MegaMenu';
import SuperHeader from '../SuperHeader';
import ReactGA from 'react-ga4';

const NavBar = () => {
  const location = useLocation();

  ReactGA.initialize([
    {
      trackingId: 'G-FRCTYN4GW6',
      gaOptions: {},
      gtagOptions: {},
    },
  ]);
  useEffect(() => {
    ReactGA.initialize('G-FRCTYN4GW6');
  },[]);
  
  const isHomePage = location.pathname === '/';

  return (
    <>
      <section className={`ssv-NavBar font-montserrat text-white bg-ssv_softwhite sticky top-0 z-10 ${isHomePage ? 'negative' : ''}`}>
        <SuperHeader></SuperHeader>
        <article className='flex flex-wrap justify-between items-center mx-auto container px-4 md:px-6 py-2.5'>
          <Logo />
          <NavLinksC />
        </article>
        <MegaMenu />
      </section>
    </>
  );
};

export default NavBar;
