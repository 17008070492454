import { Fragment } from 'react';
import { Disclosure } from '@headlessui/react';
import { Link } from 'react-router-dom';

const navigation = [
  { name: 'Home', to: '/', current: true, visible: false },
  { name: 'Calendario', to: '/calendario', current: false, visible: true },
  { name: 'Procesiones y actos', to: '/procesiones', current: false, visible: true },
  { name: 'Pasos', to: '/pasos', current: false, visible: true },
  { name: 'Cofradías', to: '/cofradias', current: false, visible: true },
  { name: 'Imagineros', to: '/imagineros', current: false, visible: true },
  { name: 'Noticias', to: '/noticias', current: false, visible: true },
  { name: 'Blog', to: '/blog', current: false, visible: true },
  { name: 'Contacto', to: '/contacto', current: false, visible: true }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Example() {
  return (
    <Disclosure as='nav' className=''>
      {({ open }) => (
        <>
          <div className='mx-auto max-w-7xl px-2 sm:px-6 lg:px-8'>
            <div className='flex h-16 items-right justify-end'>
              <div className='flex items-center lg:hidden'>
                {/* Mobile menu button*/}
                <Disclosure.Button className='inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
                  <span className='sr-only'>Open main menu</span>
                  {open ? (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth={1.5}
                      stroke='currentColor'
                      className='block w-6 h-6'
                      aria-hidden='true'>
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M6 18L18 6M6 6l12 12'
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth={1.5}
                      stroke='currentColor'
                      className='block w-6 h-6'
                      aria-hidden='true'>
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5'
                      />
                    </svg>
                  )}
                </Disclosure.Button>
              </div>
              <nav className='flex flex-none items-center justify-center lg:items-stretch lg:justify-start'>
                <div className='hidden lg:ml-6 lg:block'>
                  <div className='flex space-x-4'>
                    {navigation.map((item) => (
                      <>
                        {item.visible ? (
                          <Link
                            key={JSON.stringify(item)}
                            to={item.to}
                            className={classNames(
                              item.current
                                ? 'bg-ssv_softbeige text-white'
                                : 'text-ssv_softblack hover:bg-gray-700 hover:text-white',
                              'px-1 py-2 rounded-md text-sm font-medium font-montserrat'
                            )}
                            aria-current={item.current ? 'page' : undefined}>
                            {item.name}
                          </Link>
                        ) : null}
                      </>
                    ))}
                  </div>
                </div>
              </nav>
            </div>
          </div>

          <Disclosure.Panel className='sm:hidden'>
            <div className='space-y-1 px-2 pt-2 pb-3'>
              {navigation.map((item) => (
                <>
                  {item.visible ? (
                    <Disclosure.Button
                      key={item.name}
                      as='a'
                      href={item.to}
                      className={classNames(
                        item.current
                          ? 'bg-gray-900 text-white'
                          : 'text-ssv_softblack hover:bg-gray-700 hover:text-white',
                        'block px-3 py-2 rounded-md text-base font-medium text-right'
                      )}
                      aria-current={item.current ? 'page' : undefined}>
                      {item.name}
                    </Disclosure.Button>
                  ) : null}
                </>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
